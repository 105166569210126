<template>
  <CHeader position="sticky" class="mb-4 bg-dark">
    <CContainer fluid>
      <CHeaderBrand class="mx-auto" to="/" @click="topFunction()">
        <a class="header-brand" href="/">
          <img
            :src="logo"
            alt="Logo"
            width="78"
            height="48"
            class="d-inline-block align-top"
          />
          <svg width="220" height="48" xmlns="http://www.w3.org/2000/svg">
            <g>
              <text
                x="40%"
                y="50%"
                style="
                  dominant-baseline: central;
                  text-anchor: middle;
                  font-size: 24px;
                  font: bold 32px garamond;
                  fill: white;
                "
              >
                Sharp Quill
              </text>
            </g>
          </svg>
        </a>
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="#solution-architecture">
            <p class="text-white">Solution architecture</p>
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#enterprise-architecture">
            <p class="text-white">Enterprise architecture</p>
          </CNavLink>
        </CNavItem>
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import logo from '@/assets/images/logo2.webp'

export default {
  name: 'AppHeader',
  components: {},
  methods: {
    topFunction() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      // document.body.scrollTop = 0 // For Safari
      // document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    },
  },
  setup() {
    return {
      logo,
    }
  },
}
</script>
