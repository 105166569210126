<template>
  <CFooter class="mt-5 mb-4 bg-dark justify-content-center">
    <div>
      <!-- prettier-ignore -->
      <p class="lead text-white mb-1 mt-1 d-flex">
        Sharp Quill Consulting AB
      </p>
      <p class="text-white mb-1 d-flex">Organisation nr 556868-9292</p>
      <p class="text-white mb-1 d-flex">
        <a
          href="mailto:erik.bleckhorns@sharpquill.se"
          class="text-light text-decoration-none"
        >
          erik.bleckhorns@sharpquill.se
        </a>
      </p>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
